import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Cookies from "universal-cookie";

import { api } from "../../../services/api";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Button from "../../../components/button";
import Footer from "../../../components/footer";
import ModalFiltroCarteira from "../../../components/modal/filtroCarteira";
import MenuLateral from "../../../components/menuLateral";
import { RenderLogo } from "../../../components/logos";
import Modal from "../../../components/NewModal/center";
import { CarregandoExperiencias } from "../../../components/modal/CarregandoExperiencias";
import imgBox from "../../../assets/img/box.svg";
import InesistentSearchMagnifyingGlass from "../../../assets/img/InesistentSearchMagnifyingGlass.svg";

import {
  formatData,
  formatDataTime,
  filtroCarteira,
} from "../../../services/helper";

import { Container } from "./styles";
import { ClickAjuda } from "../../principal/styles";
import { LoadLink } from "../finalizacao/styles";

import img1 from "../../../assets/img/filtro.svg";
// import img2 from "../../../assets/img/menu-branco.svg";
import img3 from "../../../assets/img/mensagem.svg";
import img4 from "../../../assets/img/calendario.svg";
import img5 from "../../../assets/img/sair.svg";
// import img6 from "../../../assets/img/seta-voltar.svg";
import img8 from "../../../assets/img/link1.svg";
import imgVoltar from "../../../assets/img/voltar.svg";
import imgLoader from "../../../assets/img/loader-yetz-3.svg";

import { useAcaoContext } from "../../../contexts/acaoContext";

export function Carteira() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  let user = cookies.get("yp_user");
  if (!user) {
    user = JSON.parse(localStorage.getItem("yp_user"));
  }
  const { configuracao } = useAcaoContext();

  const baseUrl = `/${configuracao?.key}`;
  const origin_benefit_click_id = configuracao?.origin_benefit_click_id;
  const linkUrl =
    origin_benefit_click_id && origin_benefit_click_id !== ""
      ? `${baseUrl}?origin_benefit_click_id=${origin_benefit_click_id}`
      : baseUrl;

  const props = { wallet_counter_color: configuracao.wallet_counter_color };
  const [carteira, setCarteira] = useState([]);
  const [carteiraFiltrada, setCarteiraFiltrada] = useState([]);
  const [loadingLink, setLoadingLink] = useState(false);
  const [erroLink, setErroLink] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openFiltro, setOpenFiltro] = useState(false);

  const [activeMenu, setActiveMenu] = useState(false);
  const [modalShowWallet, setModalShowWallet] = useState(false);

  const inputRef = useRef(null);
  const [status, setStatus] = useState();
  const [dataResgate, setDataResgate] = useState([null, null]);

  const options = [
    { value: "ativo", label: "Ativo" },
    { value: "expirado", label: "Expirado" },
    { value: "todos", label: "Todos" },
  ];

  async function linkResgate(key_one, key_two) {
    // setActiveIframe(true);
    setLoadingLink(true);

    try {
      const response = await api.post(
        `/client/action/reward/link/${key_one}/${key_two}`
      );
      // setLinkIframe(response[0]);
      setLoadingLink(false);
      window.location.href = response[0];
    } catch (error) {
      setLoadingLink(false);
      if (error && error[0]) {
        setErroLink(error[0]);
      } else {
        setErroLink("Algo deu errado! Espere um pouco e recarregue a página.");
      }
    }
  }

  function filtrarCarteira(filtro, valor) {
    const busca = inputRef.current.value;
    let carteiraFiltrada = [];
    if (filtro === "status") {
      carteiraFiltrada = filtroCarteira(carteira, busca, valor, dataResgate);
    } else if (filtro === "data") {
      carteiraFiltrada = filtroCarteira(carteira, busca, status, valor);
    } else {
      carteiraFiltrada = filtroCarteira(carteira, busca, status, dataResgate);
    }
    setCarteiraFiltrada(carteiraFiltrada);
  }

  async function getMyHistory() {
    try {
      const response = await api.post(`/client/action/reward/myHistory`, {
        user_id: user?.user_id,
      });

      setCarteira(response.rewards ?? []);
      setCarteiraFiltrada(response.rewards ?? []);
    } catch (error) {
      toast.error("Algo deu errado! Espere um pouco e recarregue a página.");
      console.log(error);
      setLoading(false);
    }
  }

  async function getConfig() {
    try {
      setLoading(true);
      const response = await api.post(`/client/action/user/config`);
      return response;
    } catch (error) {
      toast.error("Algo deu errado! Espere um pouco e recarregue a página.");
      console.log(error);
      setLoading(false);
    }
  }

  async function load() {
    try {
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const [_, config] = await Promise.all([getMyHistory(), getConfig()]);
      setLoading(false);
      if (config.show_wallet === true) {
        setModalShowWallet(!modalShowWallet);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  function handleClose() {
    setModalShowWallet(!modalShowWallet);
  }

  useEffect(() => {
    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return <Navigate to={`/${configuracao?.key}`} replace />;
  }

  ///client/action/user/config

  console.log(configuracao.show_wallet);
  return (
    <>
      {loadingLink ? (
        <LoadLink>
          <img className="loader" src={imgLoader} alt="" />
        </LoadLink>
      ) : erroLink ? (
        <LoadLink>
          <div className="erro">
            <h3>Ooops!</h3>
            <p>{erroLink}</p>
          </div>
        </LoadLink>
      ) : (
        <>
          <Container backgroundColor={configuracao?.background} {...props}>
            <div className="container">
              <div className="topo">
                <div className="logos">
                  <RenderLogo {...configuracao} />
                </div>
                <div className="links-topo">
                  <div className="voltar" onClick={() => navigate(-1)}>
                    <img src={imgVoltar} alt="" />

                    <span>Voltar</span>
                  </div>
                  <Link to={linkUrl}>
                    <img src={img5} alt="" />
                    <span>Sair</span>
                  </Link>
                </div>
              </div>

              <div className="topo-titulo">
                <div className="titulo-contador">
                  <p>
                    <img src={img4} alt="" />
                    Meus Vouchers
                  </p>
                  {carteira.length === 0 ? (
                    <span className="contador-voucher">
                      <strong>{carteira.length}</strong> experiência resgatada
                      até hoje :(
                    </span>
                  ) : carteira.length === 1 ? (
                    <span className="contador-voucher">
                      <strong>{carteira.length}</strong> experiência resgatada
                      até hoje :)
                    </span>
                  ) : (
                    <span className="contador-voucher">
                      <strong>{carteira.length}</strong> experiências resgatadas
                      até hoje :)
                    </span>
                  )}
                </div>

                <div className="filtros">
                  <input
                    placeholder="Digite o que procura"
                    type="text"
                    name="busca"
                    ref={inputRef}
                  />
                  <Button
                    title="Buscar"
                    onClick={() => filtrarCarteira("busca")}
                  />
                  {/* <SelectOptions
                    options={options}
                    change={(value) => {
                      setStatus(value.value);
                      filtrarCarteira("status", value.value);
                    }}
                  /> */}
                  {/* <InputRangeCalendar
                    placeholder="Data do Resgate"
                    tipo="filtro"
                    change={(value) => {
                      setDataResgate(value);
                      if (value[0] && value[1]) {
                        filtrarCarteira("data", value);
                      }
                    }}
                  /> */}
                  <img
                    src={img1}
                    alt=""
                    className="abreFiltro"
                    onClick={() => setOpenFiltro(true)}
                  />
                </div>
              </div>
            </div>

            <div className="overlay-modal"></div>

            <div className="container carteira-container">
              {loading ? (
                <div className="loader">
                  <img src={imgLoader} alt="" />
                </div>
              ) : carteira.length === 0 ? (
                <div className="container-sem-resgates">
                  <div className="container-img">
                    <img src={imgBox} alt="box" />
                  </div>

                  <h3>Você não efetuou resgates.</h3>
                  <h4>Volte e selecione uma experiência.</h4>

                  <div className="voltar" onClick={() => navigate(-1)}>
                    <img src={imgVoltar} alt="" />

                    <span>Voltar</span>
                  </div>
                </div>
              ) : (
                <>
                  {carteiraFiltrada.length === 0 && carteira.length !== 0 && (
                    <div className="container-sem-resgates">
                      <div className="container-img">
                        <img src={InesistentSearchMagnifyingGlass} alt="box" />
                      </div>

                      <h3>Não encontramos resultado</h3>
                      <h4>nos parâmetros de busca aplicados.</h4>

                      <div className="voltar" onClick={() => navigate(-1)}>
                        <img src={imgVoltar} alt="" />

                        <span>Voltar</span>
                      </div>
                    </div>
                  )}

                  {carteiraFiltrada.map((data, index) => {
                    return (
                      <div
                        className={`premio ${
                          data.expired ? "indisponivel" : ""
                        }`}
                        key={index}
                      >
                        <div className="nome-descricao">
                          <h4>{data.name}</h4>
                        </div>
                        <p>
                          <strong>Resgate:</strong>{" "}
                          {formatDataTime(data.date_assigned)}{" "}
                        </p>
                        <div className="link">
                          <p
                            onClick={() =>
                              linkResgate(data.key_one, data.key_two)
                            }
                          >
                            <img src={img8} alt="" />
                            Abrir Link
                          </p>
                          <p>Vencimento em {formatData(data.expires_at)}</p>
                        </div>

                        <p>
                          <strong>Visualizado:</strong>{" "}
                          {data.date_visualized
                            ? formatDataTime(data.date_visualized)
                            : "Não"}{" "}
                        </p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <div className="container">
              <ClickAjuda
                onClick={() => {
                  navigate("/" + configuracao?.key + "/ajuda");
                }}
              >
                <img src={img3} alt="" />
                <span>
                  Dúvidas? <span className="clique">Clique aqui</span> e fale
                  com nosso parceiro YETZ
                </span>
              </ClickAjuda>
            </div>

            <Footer />
          </Container>

          <ModalFiltroCarteira
            carteira={carteira}
            setCarteiraFiltrada={setCarteiraFiltrada}
            isOpen={openFiltro}
            handleClose={() => setOpenFiltro(false)}
            cor={configuracao?.background}
          />
          <MenuLateral
            isOpen={activeMenu}
            handleClose={() => setActiveMenu(false)}
            acao={configuracao?.name}
            key={configuracao?.key}
          />

          <Modal open={modalShowWallet} handleClose={handleClose}>
            <CarregandoExperiencias handleClose={handleClose} />
          </Modal>
        </>
      )}
    </>
  );
}
